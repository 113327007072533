import * as React from "react";
import Layout from "../components/Layout";
import Navbar from "../components/Navbar";

const NotFoundPage = () => (
  <Layout>
    <Navbar dark externalPage />
    <section id="404" className="v-section">
      <div className="v-section__title v-section__title--light">
        <div className="container v-section__title-container">
          00
          <span className="v-section__title-line" />
          Błąd - 404
        </div>
      </div>
      <div className="v-fullwidth-content">
        <div className="container">
          <div className="v-text-content v-text-content--recipes">
            <div className="v-text-content-title">404</div>
            <div className="v-text-content-desc">Taka strona nie istnieje</div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
